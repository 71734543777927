import React from 'react'
import { Button } from 'reactstrap'
import IntlMessages from 'util/IntlMessages'

const Footer = () => (
  <footer className="app-footer">
    <div className="d-flex flex-row justify-content-between">
      <div>
        <span> Copyright 1 Change &copy; 2023</span>
      </div>
      <div>
        <Button
          color="link"
          href="https://1change.co"
          target="_blank"
          size="sm">
          1 Change website
        </Button>
      </div>
    </div>
  </footer>
)
export default Footer
