import React from 'react'
import { connect } from 'react-redux'
import { userSignOut } from 'actions/Auth'
import IntlMessages from 'util/IntlMessages'
import _ from 'lodash'

class UserInfo extends React.Component {
  render() {
    const user = _.get(this.props.authUser, 'user', {})
    const fullName = user.firstName + ' ' + user.lastName
    return (
      <div>
        <div className="user-profile">
          <img
            className="user-avatar border-0 size-40"
            src={user.profileImageUrl}
            alt="User"
          />
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{fullName}</h4>
            <small>{user.roleName}</small>
          </div>
        </div>
        {/* <a className="dropdown-item text-muted" href="javascript:void(0)"> */}
        {/* <i className="zmdi zmdi-face zmdi-hc-fw mr-1" /> */}
        {/* <IntlMessages id="popup.profile" /> */}
        {/* </a> */}
        {/* <a className="dropdown-item text-muted" href="javascript:void(0)"> */}
        {/* <i className="zmdi zmdi-settings zmdi-hc-fw mr-1" /> */}
        {/* <IntlMessages id="popup.setting" /> */}
        {/* </a> */}
        <a
          className="dropdown-item text-muted"
          href="javascript:void(0)"
          onClick={() => {
            console.log('Try to logoput')
            this.props.userSignOut()
          }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
          <IntlMessages id="popup.logout" />
        </a>
      </div>
    )
  }
}
const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth
  return {
    loader,
    alertMessage,
    showMessage,
    authUser
  }
}
export default connect(mapStateToProps, { userSignOut })(UserInfo)
