import React from 'react'
import { Route, Switch } from 'react-router'
import asyncComponent from 'util/asyncComponent'
import { ProgramNotification } from 'app/routes/program/components/ProgramNotification'

export default class ProgramRouter extends React.Component {
  render() {
    const match = this.props.match
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <Switch>
            <Route
              exact
              path={`${match.url}/:id/userData/:userDataId/talent`}
              component={asyncComponent(() => import('./TalentViewer'))}
            />
            <Route
              exact
              path={`${match.url}/:id/userData/:userDataId`}
              component={asyncComponent(() => import('./UserDataViewer'))}
            />
            <Route
              exact
              path={`${match.url}/:id/evaluation/:evaluationId`}
              component={asyncComponent(() => import('./EvaluationViewer'))}
            />
            <Route
              exact
              path={`${match.url}/latest/talent`}
              component={asyncComponent(() =>
                import('./LatestProgramTalentRoute')
              )}
            />
            <Route
              exact
              path={`${match.url}/:id`}
              component={asyncComponent(() => import('./ProgramViewer'))}
            />
            <Route
              exact
              path={`${match.url}/`}
              component={asyncComponent(() => import('./ProgramList'))}
            />
            <Route
              component={asyncComponent(() =>
                import('app/routes/extraPages/routes/404')
              )}
            />
          </Switch>
          <ProgramNotification />
        </div>
      </div>
    )
  }
}
