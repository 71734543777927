import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNIN_USER_WITH_TOKEN,
  RESET,
  SIGNUP_USER_SUCCESS,
  AUTH_UPDATE_TOKEN
} from './ActionTypes'

export const authUpdateToken = tokens => ({
  type: AUTH_UPDATE_TOKEN,
  payload: tokens
})
export const userSignUp = user => ({
  type: SIGNUP_USER,
  payload: user
})
export const userSignIn = user => ({
  type: SIGNIN_USER,
  payload: user
})
export const userSignInWithToken = (token, userId) => ({
  type: SIGNIN_USER_WITH_TOKEN,
  payload: { token, userId }
})
export const userSignOut = () => ({
  type: SIGNOUT_USER
})
export const userSignUpSuccess = authUser => ({
  type: SIGNUP_USER_SUCCESS,
  payload: authUser
})

export const userSignInSuccess = authUser => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser
})
export const userSignOutSuccess = () => ({
  type: SIGNOUT_USER_SUCCESS
})

export const showAuthMessage = message => ({
  type: SHOW_MESSAGE,
  payload: message
})
export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER
})
export const reset = () => ({
  type: RESET
})

export const setInitUrl = url => ({
  type: INIT_URL,
  payload: url
})
export const hideMessage = () => ({
  type: HIDE_MESSAGE
})
export const hideAuthLoader = () => ({
  type: ON_HIDE_LOADER
})
