import React from 'react'

export function BodyElement() {
  return (
    <p>
      Your <strong>BODY</strong>  is an incredible source of energy and intelligence. Walk n talks, regular workouts and challenging yourself physically will increase your level of Movement. Healthy Snacks, a balanced diet and staying hydrated will improve your Nutrition.
    </p>
  )
}

export function HeartElement() {
  return (
    <p>
      Your <strong>HEART</strong> is ready to sprout! Being open and trusting the guidance of your heart will help you to better feel and regulate your Emotions. Expressing your needs, being a patient listener and building trust will strengthen your Relationships. Building courage, being open to feedback and stepping out of your comfort zone will open up for greater vulnerability.
    </p>
  )
}

export function MindElement() {
  return (
    <p>
      Progress and growth opens your <strong>MIND</strong>. Meditation will expand your awareness and give you a balanced state of mind. Adapting a growth Mindset will make you see challenges as opportunities to grow. You will show more grit when things get tough and appreciate the insights from making mistakes. Getting the most important tasks done, avoiding distractions and focusing on your long term goals will improve your Efficiency.
    </p>
  )
}

export function SpiritElement() {
  return (
    <p>
      <strong>SPIRIT</strong> is the driving force of your growth! Less multitasking, regularly switching off your devices and being truly there for your loved ones will increase your Presence. Doing good things for others, donating time for social impact work and taking in praise from others will increase your level of Generosity. Making a difference in your community and taking time out to reflect on the deeper meaning of life will bring your closer to your Purpose
    </p>
  )
}

export function RecoveryElement() {
  return (
    <p>
      <strong>RECOVERY</strong> will be increased with better sleep, regular daily breaks and when taking time out for yourself.
    </p>
  )
}

export function CommentElement() {
  return (
    <p>
      Please answer the following questions
    </p>
  )
}

export function ResilienceElements() {
  return (
    <div>
      <BodyElement />
      <HeartElement />
      <MindElement />
      <SpiritElement />
      <RecoveryElement />
    </div>
  )
}
