import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import 'react-toggle-switch/dist/css/switch.min.css'
import 'rc-drawer/assets/index.css'
import 'styles/bootstrap.scss'
import 'styles/app.scss'
import 'styles/app-rtl.scss'
import 'react-notifications/lib/notifications.css'
import MainApp from 'app/index'
import asyncComponent from 'util/asyncComponent'
import AppLocale from '../lngProvider'
import SignIn from './SignIn'
import SignUp from './SignUp'
import { setInitUrl } from '../actions/Auth'
import Can from '../permissions/Can'

let RestrictedRoute = ({
  component: Component,
  setInitUrl,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (authUser) {
        return <Component {...props} />
      } else {
        setInitUrl(props.history.location.pathname)
        return (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        )
      }
    }}
  />
)
RestrictedRoute = connect(null, { setInitUrl })(RestrictedRoute)

class App extends Component {
  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname)
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      initURL,
      isDirectionRTL,
      authUser
    } = this.props
    // const authUser = '1';

    if (authUser === null) {
      if (location.pathname === '/') {
        return <Redirect to="/signin" />
      }
    } else {
      if (['/', '/signin'].includes(location.pathname)) {
        if (['', '/', '/signin', '/app/program'].includes(initURL)) {
          return (
            <div>
              <Can I="manage" on="all">
                {() => <Redirect to="/app/program" />}
              </Can>
              <Can not I="manage" on="all">
                {() => <Redirect to="/app/program/latest/talent" />}
              </Can>
            </div>
          )
        } else return <Redirect to={`${initURL}?printFriendly=true`} />
      }
    }

    // for RTL Support
    if (isDirectionRTL) {
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
    }

    const currentAppLocale = AppLocale[locale.locale]
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <div className="app-main">
          <Switch>
            <RestrictedRoute
              path={`${match.url}app`}
              authUser={authUser}
              component={MainApp}
            />
            <Route path="/signin" component={SignIn} />
            <Route path="/logout" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route
              component={asyncComponent(() =>
                import('app/routes/extraPages/routes/404')
              )}
            />
          </Switch>
        </div>
      </IntlProvider>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, isDirectionRTL } = settings
  const { authUser, initURL } = auth
  return {
    locale,
    isDirectionRTL,
    authUser,
    initURL
  }
}

export default connect(mapStateToProps, { setInitUrl })(App)
