import React from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { Route, Switch } from 'react-router'
import asyncComponent from 'util/asyncComponent'

class EvaluationRouter extends React.Component {
  render() {
    const match = this.props.match
    return (
      <div className="app-wrapper">
        <ContainerHeader title="Evaluation" match={match} />
        <div className="animated slideInUpTiny animation-duration-3">
          <Switch>
            <Route
              path={`${match.url}/:id`}
              component={asyncComponent(() =>
                import('../program/EvaluationViewer')
              )}
            />
            <Route
              component={asyncComponent(() =>
                import('app/routes/extraPages/routes/404')
              )}
            />
          </Switch>
        </div>
      </div>
    )
  }
}

export default EvaluationRouter
