import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  FETCH_ALL_ROLES,
  FETCH_ALL_ROLES_SUCCESS,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  HIDE_MESSAGE,
  PUT_USER,
  PUT_USER_SUCCESS,
  RESET,
  SHOW_MESSAGE
} from 'actions/ActionTypes'

const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  user: {},
  loader: false,
  users: [],
  roles: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_USER:
    case PUT_USER:
    case DELETE_USER:
    case FETCH_ALL_ROLES:
    case FETCH_ALL_USERS: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: true
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'Successfully created user',
        showMessage: true,
        user: action.payload.body[0]
      }
    }
    case PUT_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'Successfully edited user',
        showMessage: true,
        user: action.payload.body
      }
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'Successfully deleted user',
        showMessage: true,
        user: action.payload.body
      }
    }
    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        users: action.payload.body
      }
    }
    case FETCH_ALL_ROLES_SUCCESS: {
      return {
        ...state,
        loader: false,
        roles: action.payload.body
      }
    }
    case RESET: {
      return INIT_STATE
    }
    default:
      return state
  }
}
