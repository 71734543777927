import Swagger from 'swagger-client'
import _ from 'lodash'
import co from 'co'
import { authUpdateToken, userSignOut } from '../../actions/Auth'
import { spec } from '../api/spec'
import { reduxStore } from '../../MainApp'

export const RESPONSE_MESSAGES = {
  EXPIRED_ACCESS_TOKEN: 'Expired Access Token',
  EXPIRED_REFRESH_TOKEN: 'Expired Refresh Token'
}

// if (process.env.NODE_ENV === 'production') {
  spec.host = '360-api-prod.cloud.1change.co'
  spec.schemes = ['https']
// }
/**
 * return a secure client that stubbed with authentications
 * and token refreshing mechanism
 * @returns {Generator<*>}
 */
export function securedClient() {
  let swaggerExecute = Swagger.execute

  // We wrap this function as a temporary workaround while SwaggerJS
  // fixes an issue where the accept header wouldn't be set despite the spec
  // declaring producing content with a specific mime type.
  // More info: https://github.com/swagger-api/swagger-js/issues/1116
  // "http","fetch","spec","operationId","pathName","method","parameters","securities"
  Swagger.execute = function execute({
    http,
    fetch, // This is legacy
    spec,
    operationId,
    pathName,
    method,
    parameters,
    securities,
    ...extras
  }) {
    parameters = parameters || {}
    parameters.authorization = 'required'
    return swaggerExecute.call(this, {
      http,
      fetch, // This is legacy
      spec,
      operationId,
      pathName,
      method,
      parameters,
      securities,
      ...extras
    })
  }

  return Swagger({
    spec: spec,
    requestInterceptor: req => {
      const auth = reduxStore.getState().auth
      // console.log(auth)
      // console.log(req)
      req.headers.authorization = _.get(auth, 'authUser.accessToken', 'noToken')
      req.headers['Content-Type'] = 'application/json'
      return req
    },
    responseInterceptor: resInterceptor
  })
}

const resInterceptor = co.wrap(function*(res, obj) {
  const auth = reduxStore.getState().auth
  console.log(auth)
  console.log(res)
  console.log(obj)

  // update if there are new tokens
  if (res.headers['x-access-token']) {
    updateTokens(res.headers)
  }
  // redo request if accessToken is expired
  else if (
    res.status === 401 &&
    res.body.message === RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN
  ) {
    console.log(RESPONSE_MESSAGES.EXPIRED_ACCESS_TOKEN, res)
    swapRefreshToken(_.get(auth, 'authUser.refreshToken', ''))

    // TODO: do another request here with refresh token
    // Swagger.http(obj)
    // return internals.get(url, params, options)
  }
  // If the token was invalid or the Refresh Token was expired, force user to login
  else if (res.status === 401) {
    console.log('authInterceptor.service: 401: response:', res)
    reduxStore.dispatch(userSignOut())
  }
  //
  return res
})

function swapRefreshToken(refreshToken) {
  const tokens = {
    accessToken: refreshToken
  }
  reduxStore.dispatch(authUpdateToken(tokens))
}

function updateTokens(headers) {
  const tokens = {
    accessToken: headers['x-access-token'],
    refreshToken: headers['x-refresh-token']
  }
  reduxStore.dispatch(authUpdateToken(tokens))
}

/**
 * Expose default API without authorization and token mechanism
 */
// export default API
