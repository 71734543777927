import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import asyncComponent from '../../../util/asyncComponent'
import { UserNotification } from 'app/routes/users/components/UserNotification'

const Table = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import('./routes/data/index'))}
      />
      <Route
        component={asyncComponent(() =>
          import('app/routes/extraPages/routes/404')
        )}
      />
    </Switch>
    <UserNotification />
  </div>
)

export default Table
