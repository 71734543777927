/* eslint-disable indent */
import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import IntlMessages from 'util/IntlMessages'
import CustomScrollbars from 'util/CustomScrollbars'
import Can from '../../permissions/Can'

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props
    const that = this
    const pathname = `#${history.location.pathname}` // get current path

    const subMenuLi = document.querySelectorAll('.sub-menu > li')
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function(event) {
        event.stopPropagation()
      }
    }

    const menuLi = document.getElementsByClassName('menu')
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li')
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open')
          }
        }
        this.classList.toggle('open')
        event.stopPropagation()
      }
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`) // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul') // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open')
      } else {
        this.closest(activeLi, 'li').classList.add('open')
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn
      // find vendor prefix
      ;[
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector'
      ].some(fn => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn
          return true
        }
        return false
      })

      let parent

      // traverse parents
      while (el) {
        parent = el.parentElement
        if (parent && parent[matchesFn](selector)) {
          return parent
        }
        el = parent
      }
    } catch (e) {}

    return null
  }

  render() {
    return (
      <CustomScrollbars
        className="scrollbar"
        style={{ height: 'calc(100vh - 70px)' }}>
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>

          <Can I="list" on="Program">
            {() => (
              <li className="menu">
                <a role="button" href="javascript:void(0)">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text">Programs</span>
                </a>
                <ul className="sub-menu">
                  {/* <li> */}
                  {/* <NavLink className="prepend-icon" to="/app/dashboard/default"> */}
                  {/* <span className="nav-text">Dashboard</span> */}
                  {/* </NavLink> */}
                  {/* </li> */}
                  <li>
                    <NavLink className="prepend-icon" to="/app/program">
                      <span className="nav-text">List</span>
                    </NavLink>
                  </li>
                  {/* <li> */}
                  {/* <NavLink className="prepend-icon" to="/app/resilienceReport"> */}
                  {/* <i className="zmdi zmdi-chart zmdi-hc-fw" /> */}
                  {/* <span className="nav-text text-transform-none"> */}
                  {/* Sample reports */}
                  {/* </span> */}
                  {/* </NavLink> */}
                  {/* </li> */}
                </ul>
              </li>
            )}
          </Can>

          <Can I="list" on="User">
            {() => (
              <li className="menu">
                <a role="button" href="javascript:void(0)">
                  <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                  <span className="nav-text">Users</span>
                </a>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/user">
                      <span className="nav-text">List</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
          </Can>
          <Can I="list" on="Survey">
            {() => (
              <li className="menu">
                <a role="button" href="javascript:void(0)">
                  <i className="zmdi zmdi-collection-item-8 zmdi-hc-fw" />
                  <span className="nav-text">Surveys</span>
                </a>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/survey">
                      <span className="nav-text">List</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
          </Can>
        </ul>
      </CustomScrollbars>
    )
  }
}

export default withRouter(SidenavContent)
