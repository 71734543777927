import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  downloadUserProgramReportSuccess,
  fetchProgramsSuccess,
  deleteProgramSuccess,
  fetchProgramSuccess,
  fetchSurveysSuccess,
  sendInvitationSuccess,
  fetchUserDataSuccess,
  fetchEvaluationSuccess,
  showMessage
} from 'actions/Program'
import {
  ADD_USER_TO_PROGRAM,
  DOWNLOAD_USER_PROGRAM_REPORT,
  FETCH_ALL_EVALUATIONS,
  FETCH_ALL_PROGRAMS,
  FETCH_ALL_SURVEYS,
  FETCH_PROGRAM,
  PUT_EVALUATION,
  DELETE_EVALUATION,
  FETCH_EVALUATION,
  FETCH_USER_DATA_EVALUATIONS,
  REMOVE_USER_TO_PROGRAM,
  SEND_INVITATION,
  FETCH_SURVEY,
  CREATE_PROGRAM,
  PUT_PROGRAM,
  DELETE_PROGRAM,
  SEND_EVALUATION_REMINDER
} from 'actions/ActionTypes'
import { securedClient } from '../sagas/api/APIClient'
import { fetchEvaluationsSuccess, fetchSurveySuccess } from 'actions'
import _ from 'lodash'

const getPrograms = async () =>
  securedClient()
    .then(client =>
      client.apis.program.getProgram({ $embed: ['users', 'survey'] })
    )
    .then(d => d)
    .catch(error => error)

const getSurveys = async () =>
  securedClient()
    .then(client => client.apis.survey.getSurvey())
    .then(d => d)
    .catch(error => error)

const getUserData = async (surveyId, _id) =>
  securedClient()
    .then(client =>
      client.apis.program.getProgramOwneridUser({
        $where: JSON.stringify({ users: { $in: [_id] } })
      })
    )
    .then(d => d)
    .catch(error => error)

const getEvaluations = async (_ids = []) =>
  securedClient()
    .then(client => {
      if (_ids.length > 0) {
        // .where({ _id: { $in: data } })
        return client.apis.evaluation.getEvaluation({
          $where: JSON.stringify({ _id: { $in: _ids } }),
          $embed: ['contributor', 'recipient']
        })
      } else {
        return client.apis.evaluation.getEvaluation({
          $embed: ['contributor', 'recipient']
        })
      }
    })
    .then(d => d)
    .catch(error => error)

function* fetchEvaluations({ _ids }) {
  try {
    // console.log({ _ids })
    const fetchedData = yield call(getEvaluations, _ids)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchEvaluationsSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}
function* downloadUserProgramReport({ payload }) {
  const downloadUserProgramReportRequest = async (
    _id,
    userProgramId,
    isSample,
    emailUser
  ) =>
    securedClient()
      .then(client =>
        client.apis.program.getProgram_idUserdataUserprogramidGeneratereport({
          _id,
          userProgramId,
          isSample,
          emailUser
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { programId, userProgramId, isSample, emailUser } = payload
    const fetchedData = yield call(
      downloadUserProgramReportRequest,
      programId,
      userProgramId,
      isSample,
      emailUser
    )
    console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
      yield put(downloadUserProgramReportSuccess({ body: null }))
    } else {
      emailUser
        ? yield put('Successfully sent report')
        : yield put('Successfully generated report')
      yield put(downloadUserProgramReportSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
    yield put(downloadUserProgramReportSuccess({ body: null }))
  }
}

function* fetchUserDataWithEvaluations({ payload }) {
  try {
    const { programId, _id } = payload
    const program = yield call(fetchProgramRequest, { payload: programId })
    // clean up store
    yield put(fetchEvaluationsSuccess({ body: { docs: [] } }))
    if (program) {
      yield put(fetchProgramSuccess({ body: program }))
      const userData = _.find(_.get(program, 'users', []), ['_id', _id]) || {}
      yield put(fetchUserDataSuccess({ body: userData }))
      const evaluations = _.get(userData, 'evaluations', [])
      // console.log({ evaluations })
      if (evaluations.length > 0) {
        yield call(fetchEvaluations, { _ids: evaluations })
      }
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* fetchProgramsRequest() {
  try {
    const fetchedData = yield call(getPrograms)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchProgramsSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* addUserToProgram({ payload }) {
  try {
    const { userId, programId } = payload
    const putProgramOwneridUserChildid = async (userId, programId) =>
      securedClient()
        .then(client =>
          client.apis.program.putProgramOwneridUserChildid({
            ownerId: programId,
            childId: userId,
            body: {}
          })
        )
        .then(d => d)
        .catch(error => error)

    const fetchedData = yield call(
      putProgramOwneridUserChildid,
      userId,
      programId
    )

    console.log({ addUserToProgram: fetchedData })
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield call(fetchProgramRequest, { payload: programId })
      yield put(showMessage('Successfully added user and sent an invitation'))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* sendEvaluationReminderRequest({ payload }) {
  try {
    const { _id } = payload
    const postEvaluationReminder = async _id =>
      securedClient()
        .then(client =>
          client.apis.evaluation.postEvaluation_idReminder({
            _id: _id
          })
        )
        .then(d => d)
        .catch(error => error)

    const fetchedData = yield call(postEvaluationReminder, _id)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(showMessage('Successfully sent a reminder'))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* removeUserToProgram({ payload }) {
  const deleteProgramOwneridUserChildid = async (userId, programId) =>
    securedClient()
      .then(client =>
        client.apis.program.deleteProgramOwneridUserChildid({
          ownerId: programId,
          childId: userId
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { userId, programId } = payload
    const fetchedData = yield call(
      deleteProgramOwneridUserChildid,
      userId,
      programId
    )

    console.log({ removeUserToProgram: fetchedData })
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield call(fetchProgramRequest, { payload: programId })
      yield put(showMessage('Successfully removed user from program'))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* createProgramRequest({ payload }) {
  const postProgram = async program =>
    securedClient()
      .then(client => client.apis.program.postProgram({ body: [program] }))
      .then(d => d)
      .catch(error => error)

  try {
    const { program } = payload
    const fetchedData = yield call(postProgram, program)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(showMessage('Successfully created a program'))
      yield call(fetchProgramsRequest)
      yield put(fetchProgramSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* deleteProgramRequest({ payload }) {
  const deleteProgram = async _id =>
    securedClient()
      .then(client =>
        client.apis.program.deleteProgram_id({
          _id: _id,
          body: { hardDelete: true }
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { _id } = payload
    const fetchedData = yield call(deleteProgram, _id)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(showMessage('Successfully deleted a program'))
      yield call(fetchProgramsRequest)
      yield put(deleteProgramSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* deleteEvaluationRequest({ payload }) {
  const deleteEvaluation = async _id =>
    securedClient()
      .then(client =>
        client.apis.evaluation.deleteEvaluation_id({
          _id: _id,
          body: { hardDelete: true }
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { _id } = payload
    const fetchedData = yield call(deleteEvaluation, _id)

    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(showMessage('Successfully deleted an evaluation'))
      const userData = yield select(state => state.programs.userData)
      yield call(fetchUserDataWithEvaluations, {
        payload: { programId: userData.program, _id: userData._id }
      })
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* putProgramRequest({ payload }) {
  const putProgram = async (_id, program) =>
    securedClient()
      .then(client =>
        client.apis.program.putProgram_id({ _id: _id, body: program })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { _id, program } = payload
    const fetchedData = yield call(putProgram, _id, program)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(showMessage('Successfully updated a program'))
      yield call(fetchProgramsRequest)
      yield put(fetchProgramSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* fetchProgramRequest({ payload }) {
  const getProgram = async _id =>
    securedClient()
      .then(client =>
        client.apis.program.getProgram_id({ _id, $embed: ['users', 'survey'] })
      )
      .then(d => d)
      .catch(error => error)

  try {
    let _id = payload
    // console.log({ _id })
    const fetchedData = yield call(getProgram, _id)

    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchProgramSuccess(fetchedData))
      return fetchedData.body
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* sendInvitationRequest({ payload }) {
  const postEvaluation = async evaluation =>
    securedClient()
      .then(client =>
        client.apis.evaluation.postEvaluation({
          body: evaluation
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    // console.log({ _id })
    const { evaluation, userDataId } = payload
    const fetchedData = yield call(postEvaluation, evaluation)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield call(fetchUserDataWithEvaluations, {
        payload: { programId: evaluation.program, _id: userDataId }
      })
      yield put(sendInvitationSuccess(fetchedData))
      return fetchedData.body
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* putEvaluationRequest({ payload }) {
  const putEvaluation = async (_id, evaluation) =>
    securedClient()
      .then(client =>
        client.apis.evaluation.putEvaluation_id({
          _id: _id,
          body: evaluation
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { evaluation, _id } = payload
    console.log({ payload })
    const fetchedData = yield call(putEvaluation, _id, evaluation)
    console.log({ fetchedData })
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchEvaluationSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* getEvaluationRequest({ payload }) {
  const getEvaluation = async _id =>
    securedClient()
      .then(client =>
        client.apis.evaluation.getEvaluation_id({
          _id,
          $embed: ['contributor', 'recipient']
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { _id } = payload
    const fetchedData = yield call(getEvaluation, _id)
    console.log({ fetchedData })
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchEvaluationSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* getSurveyRequest({ payload }) {
  const getSurvey = async _id =>
    securedClient()
      .then(client =>
        client.apis.survey.getSurvey_id({
          _id
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const { _id } = payload
    const fetchedData = yield call(getSurvey, _id)
    console.log({ fetchedData })
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchSurveySuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* fetchSurveysRequest() {
  try {
    const fetchedData = yield call(getSurveys)

    console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchSurveysSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(SEND_INVITATION, sendInvitationRequest),
    takeLatest(SEND_EVALUATION_REMINDER, sendEvaluationReminderRequest),
    takeLatest(FETCH_PROGRAM, fetchProgramRequest),
    takeLatest(CREATE_PROGRAM, createProgramRequest),
    takeLatest(PUT_PROGRAM, putProgramRequest),
    takeLatest(DELETE_PROGRAM, deleteProgramRequest),
    takeLatest(PUT_EVALUATION, putEvaluationRequest),
    takeLatest(DELETE_EVALUATION, deleteEvaluationRequest),
    takeLatest(FETCH_EVALUATION, getEvaluationRequest),
    takeLatest(FETCH_SURVEY, getSurveyRequest),
    takeLatest(FETCH_USER_DATA_EVALUATIONS, fetchUserDataWithEvaluations),
    takeLatest(DOWNLOAD_USER_PROGRAM_REPORT, downloadUserProgramReport),
    takeLatest(FETCH_ALL_PROGRAMS, fetchProgramsRequest),
    takeLatest(FETCH_ALL_SURVEYS, fetchSurveysRequest),
    takeLatest(ADD_USER_TO_PROGRAM, addUserToProgram),
    takeLatest(REMOVE_USER_TO_PROGRAM, removeUserToProgram),
    takeLatest(FETCH_ALL_EVALUATIONS, fetchEvaluations)
  ])
}
