import { applyMiddleware, compose, createStore } from 'redux'
import createHistory from 'history/createHashHistory'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'react-router-redux'
import rootSaga from '../sagas/index'
import reducers from '../reducers/index'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
// Logger with default options

const history = createHistory()
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, routeMiddleware]

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`)
  middlewares.push(logger)
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['settings']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  )
  let persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }
  return { store, persistor }
}
export { history }
