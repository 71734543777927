import React from 'react'
import { animateScroll } from 'react-scroll'
import SurveyQuestionItem from './SurveyQuestionItem'
import { connect } from 'react-redux'
import { fetchSurvey } from 'actions/index'
import _ from 'lodash'
import { Form, Button } from 'reactstrap'
import { reduxForm } from 'redux-form'
import {
  BodyElement,
  CommentElement,
  HeartElement,
  MindElement,
  RecoveryElement,
  SpiritElement
} from 'app/routes/program/Component/ResilienceElements'

const CATEGORY_ELEMENTS = {
  Body: <BodyElement />,
  Heart: <HeartElement />,
  Mind: <MindElement />,
  Spirit: <SpiritElement />,
  Recovery: <RecoveryElement />,
  Comment: <CommentElement />
}

class SurveyViewer extends React.Component {
  componentWillMount() {
    const surveyId = _.get(
      this.props,
      'match.params.surveyId',
      this.props.surveyId
    )
    // const survey = _.find(this.props.surveys, ['_id', surveyId]) || {}
    this.props.fetchSurvey(surveyId)
  }
  render() {
    const questionField = _.get(
      this.props,
      'match.params.questionField',
      this.props.questionField
    )
    const survey = this.props.survey
    const evaluation = _.get(this.props, 'evaluation', {})
    const evaluationBody = _.get(evaluation, 'body', {})
    const surveyBody = (survey.body || []).filter(
      p => p[questionField] && p[questionField].length > 0
    )
    return (
      <div id="survey-form" className="row justify-content-center">
        <div className="jr-card col-lg-12 col-md-12 col-sm-12">
          <div className="">
            {evaluation.relationship !== 'self' ? (
              <p>
                (*) If you're not sure, leave unchecked or double click on the
                rating to clear the answer
              </p>
            ) : (
              ''
            )}
            <Form onSubmit={this.props.handleSubmit}>
              <div>
                {_.map(CATEGORY_ELEMENTS, (element, category) => (
                  <div>
                    {surveyBody.filter(p => p.category === category).length > 0
                      ? element
                      : ''}
                    <hr />
                    {surveyBody.map((p, index) => {
                      if (p.category !== category) return ''
                      const defaultAnswer = _.get(
                        _.find(
                          evaluationBody,
                          q => q.qid.toString() === p.qid.toString()
                        ),
                        'answer'
                      )
                      console.log({ defaultAnswer })
                      return (
                        <SurveyQuestionItem
                          key={index}
                          orderIdx={index}
                          defaultAnswer={defaultAnswer}
                          relationship={evaluation.relationship}
                          data={p}
                          _id={p.qid}
                          category={p.category}
                          questionField={questionField}
                          question={p[questionField]}
                        />
                      )
                    })}
                    <br />
                  </div>
                ))}
              </div>
              <Button disabled={this.props.submitting}>Submit</Button>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
const scrollToFirstError = errors => {
  const errorFields = getErrorFieldNames(errors)
  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i++) {
    const fieldName = `${errorFields[i]}`
    // Checking if the marker exists in DOM
    const elements = document.querySelectorAll(`[name="${fieldName}"]`)
    console.log({ element: elements[0].offsetTop })
    if (elements.length) {
      animateScroll.scrollTo(elements[0].offsetTop + 50)
      break
    }
  }
}

const alertError = errors => {
  const errorFields = getErrorFieldNames(errors)
  const questionIndices = errorFields.map(e => {
    let orderIdx = _.replace(e, 'question-', '')
    orderIdx = _.replace(orderIdx, /-[\d]*/, '')
    return orderIdx * 1 + 1
  })
  alert(`Please answer the following questions: ${questionIndices}`)
}

const getErrorFieldNames = (obj, name = '') => {
  const errorArr = []
  errorArr.push(
    Object.keys(obj)
      .map((key, idx) => {
        const next = obj[key]
        if (next) {
          if (typeof next === 'string') {
            return name + key
          }
          // Keep looking
          if (next.map) {
            errorArr.push(
              next
                .map((item, index) =>
                  getErrorFieldNames(item, `${name}${key}[${index}].`)
                )
                .filter(o => o)
            )
          }
        }
        return null
      })
      .filter(o => o)
  )
  return _.flattenDeep(errorArr)
}

const mapDispatchToProps = dispatch => ({
  fetchSurvey: id => dispatch(fetchSurvey(id))
})

const mapStateToProps = state => ({
  survey: _.get(state, 'programs.survey', {})
})

const SurveyViewerWrapper = connect(mapStateToProps, mapDispatchToProps)(
  SurveyViewer
)
export default reduxForm({
  // a unique name for the form
  form: 'evaluation',
  onSubmitFail: (errors, dispatch, submitError, props) => {
    console.log({ errors, dispatch, submitError, props })
    scrollToFirstError(errors)
    setTimeout(() => alertError(errors), 1000)
  }
})(SurveyViewerWrapper)
