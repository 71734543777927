import _ from 'lodash'
import connect from 'react-redux/es/connect/connect'
import { hideMessage } from 'actions'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import React from 'react'

const mapStateToProps = state => ({
  alertMessage: _.get(state, 'users.alertMessage', null),
  showMessage: _.get(state, 'users.showMessage', null)
})
export const UserNotification = connect(mapStateToProps, { hideMessage })(
  ({ showMessage, hideMessage, alertMessage }) => (
    <div>
      {showMessage && NotificationManager.info(`${alertMessage}`, hideMessage)}
      <NotificationContainer />
    </div>
  )
)
