import React from 'react'
import { MINI_DRAWER } from 'actions/ActionTypes'
import NewLogo from 'assets/images/new_logo.svg'

const SidenavLogo = ({ drawerType }) => {
  const showMini = drawerType.includes(MINI_DRAWER)

  return (
    <div className="sidebar-header d-flex align-items-center app-logo">
      {showMini ? (
        <div className="mini-logo">
          <img className="mini-logo-img" src={NewLogo} />
          <img className="mini-logo-img-hover" src={NewLogo} />
        </div>
      ) : (
        <img className="" src={NewLogo} />
      )}
    </div>
  )
}

export default SidenavLogo
