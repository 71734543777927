import React from 'react'
// import './survey.less'
import Rate from 'rc-rate'
import { Element as ScrollElement } from 'react-scroll'
import 'rc-rate/assets/index.css'
import './question-item.css'
import { Badge, FormGroup, Label, Input } from 'reactstrap'
import { Field } from 'redux-form'
const RATING_QCAT = ['Heart', 'Body', 'Mind', 'Recovery', 'Spirit']
const RATINGS = [
  'Strongly disagree',
  'Disagree',
  'Somewhat disagree',
  'Somewhat agree',
  'Agree',
  'Strongly agree'
]
const required = value =>
  value || typeof value === 'number' ? undefined : 'Required'
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minLength2 = minLength(2)
const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  defaultAnswer
}) => {
  input.value = input.value || defaultAnswer
  return (
    <div>
      <div>
        <Input {...input} placeholder={label} type={type} />
        {touched &&
          error && (
            <Badge color="danger" pill>
              {error}
            </Badge>
          )}
      </div>
    </div>
  )
}
const renderRating = ({
  input,
  label,
  type,
  category,
  relationship,
  meta: { touched, error },
  defaultAnswer
}) => {
  console.log({ defaultAnswer })
  input.value = input.value || defaultAnswer
  return (
    <div>
      <div>
        <Rate
          character="●"
          className={`rc-${category.toLowerCase()}`}
          style={{ fontSize: '40px' }}
          count={6}
          defaultValue={defaultAnswer}
          value={input.value}
          allowClear={relationship !== 'self'}
          onChange={value => input.onChange(value)}
          // onHoverChange={rate => this.setState({ rate })}
          // onTouchEnd={() => this.setState({ value: this.state.rate })}
        />
        <div className="d-md-inline">
          <Badge className={`bg-${category.toLowerCase()}`}>
            {RATINGS[input.value - 1]}
          </Badge>
          {touched &&
            error && (
              <Badge color="danger" pill>
                {error}
              </Badge>
            )}
        </div>
      </div>
    </div>
  )
}
class SurveyQuestionItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rate: null,
      value: null
    }
  }
  render() {
    const {
      _id,
      orderIdx,
      question,
      category,
      relationship,
      defaultAnswer
    } = this.props

    return RATING_QCAT.includes(category) ? (
      <ScrollElement
        name={`question-${orderIdx}-${_id}`}
        id={`question-${orderIdx}-${_id}`}>
        <FormGroup>
          <Label>
            <strong>
              {orderIdx + 1} - {category}
            </strong>
            {` - ${question}`}
            <div className="form-control-range">
              <Field
                component={renderRating}
                relationship={relationship}
                category={category}
                defaultAnswer={defaultAnswer}
                type="number"
                name={`question-${orderIdx}-${_id}`}
                id={`${category}${_id}`}
                validate={
                  relationship === 'self' ? [required, number] : [number]
                }
              />
            </div>
          </Label>
        </FormGroup>
      </ScrollElement>
    ) : category === 'Comment' ? (
      <ScrollElement name={`question-${orderIdx}-${_id}`}>
        <FormGroup name={`question-${orderIdx}-${_id}`}>
          <Label>
            <strong>Question</strong>
            {` - ${question}`}
          </Label>
          <Field
            component={renderField}
            defaultAnswer={defaultAnswer}
            type="textarea"
            name={`question-${orderIdx}-${_id}`}
            id={`${category}${_id}`}
            validate={[required, minLength2]}
          />
        </FormGroup>
      </ScrollElement>
    ) : (
      ''
    )
  }
}
export default SurveyQuestionItem
