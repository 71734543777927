import { createCanBoundTo } from '@casl/react'
import { connect } from 'react-redux'
import _ from 'lodash'
import React from 'react'
import { defineAbilitiesWith } from './ability'

const mapStateToProps = state => ({
  ability: defineAbilitiesWith(_.get(state, 'auth.rules', []))
})
export default connect(mapStateToProps)(createCanBoundTo())
