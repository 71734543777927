import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import IntlMessages from 'util/IntlMessages'
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  hideAuthLoader,
  reset,
  userSignInWithToken
} from 'actions/Auth'
import CircularProgress from 'components/CircularProgress'
import queryString from 'query-string'
import NewLogo from 'assets/images/new_logo.svg'
import URL from "util/url";

class SignIn extends React.Component {
  constructor(props) {
    super()
    props.reset()
    this.fromPath = _.get(props, 'location.state.from.pathname', '/')
    this.query = _.get(props, 'location.state.from.search', '')
    var parsed = queryString.parse(this.query)
    // http://localhost:3000/#/signin?email=test@superadmin.com&password=root&autoLogin=true
    // http://localhost:3000/#/app/program/5b5c31dc43414722c5194f34/userData/5b5c31dd0e08878af7ca417c?email=test@superadmin.com&password=root&autoLogin=true
    this.state = {
      email: parsed.email || '',
      password: parsed.password || '',
      token: parsed.token || null,
      userId: parsed.userId || '',
      autoLogin: parsed.autoLogin || false
    }
  }

  componentDidMount() {
    if (this.state.autoLogin === 'true') {
      if (this.state.token) {
        this.props.userSignInWithToken(this.state.token, this.state.userId)
      } else {
        this.props.userSignIn({
          email: this.state.email,
          password: this.state.password
        })
      }
    }
    this.props.hideAuthLoader()
  }

  // componentDidUpdate() {
  //   alert('componentDidUpdate');
  //   if (this.props.showMessage) {
  //     setTimeout(() => {
  //       this.props.hideMessage()
  //     }, 100)
  //   }
  //   if (this.props.authUser !== null) {
  //     const { printFriendly } = new URL().getParams();
  //     alert(JSON.stringify({printFriendly}));
  //     this.props.history.push(new URL().getParams(this.fromPath).replaceParams({printFriendly}).getUrl())
  //   }
  // }

  render() {
    const { email, password } = this.state
    const { showMessage, loader, alertMessage } = this.props
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img className="" src={NewLogo} />
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.email" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <div className="form-group mb-3">
                  <input
                    placeholder="Email"
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                    defaultValue={email}
                    className="form-control form-control-lg"
                  />
                </div>

                <div className="form-group mb-3">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    className="form-control form-control-lg"
                  />
                </div>

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    onClick={() => {
                      this.props.showAuthLoader()
                      this.props.userSignIn({ email, password })
                    }}
                    color="primary"
                    className="text-uppercase">
                    <IntlMessages id="appModule.signIn" />
                  </Button>

                  {/* <Link to="/signup"> */}
                  {/* <IntlMessages id="signIn.signUp" /> */}
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(`${alertMessage}`)}
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth
  return {
    loader,
    alertMessage,
    showMessage,
    authUser
  }
}

export default connect(mapStateToProps, {
  reset,
  userSignIn,
  hideMessage,
  hideAuthLoader,
  userSignInWithToken,
  showAuthLoader
})(SignIn)
