import { Ability, AbilityBuilder } from '@casl/ability'

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item
  }
  return item.__type
}

export function defineAbilitiesFor(user) {
  const ADMIN_ROLES = ['Admin', 'Super Admin']
  return AbilityBuilder.define({ subjectName }, (can, cannot) => {
    if (ADMIN_ROLES.includes(user.roleName)) {
      can('manage', 'all')
      can(['list'], 'Program')
      can(['list'], 'Survey')
      can(['list'], 'User')
      can(['list'], 'UserProgram')
    } else {
      can(['read'], 'Program', {
        users: { $elemMatch: { 'user._id': user._id } }
      })
      can(['list'], 'Program')
      can(['read', 'edit', 'delete'], 'User', { _id: user._id })
      can(['create', 'update'], 'Evaluation', {
        recipient: user._id,
        relationship: 'self'
      })
      can(['create', 'read', 'delete'], 'Evaluation', {
        recipient: user._id,
        relationship: { $ne: 'self' }
      })
    }
  })
}

export function defineAbilitiesWith(rules) {
  return new Ability(rules, { subjectName })
}

export function defineRulesFor(user) {
  return defineAbilitiesFor(user).rules
}
