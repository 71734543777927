import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'

function SurveyListItem({ styleName, data, match }) {
  const { _id, changelog, version, name, lastUpdate } = data
  const image = 'https://www.gravatar.com/avatar/' + _id + '?r=PG&d=identicon'
  return (
    <div className="user-list d-sm-flex flex-sm-row card">
      <img alt="..." src={image} className="user-avatar border-0" />
      <div className="description">
        <h3>{name}</h3>
        <p>Last Update: {lastUpdate}</p>
        <p>Changelog: {changelog}</p>
        <p>Version: {version}</p>
        <ListGroup className="list-inline d-sm-flex flex-sm-row gx-btn-list">
          <ListGroupItem className="border-0">
            <Link
              to={`${match.url}/${_id}/questionParticipant`}
              className="btn btn-light jr-btn-rounded">
              View Talent Survey
            </Link>
            <Link
              to={`${match.url}/${_id}/questionManagerOfMale`}
              className="btn btn-light jr-btn-rounded">
              View Manager Survey
            </Link>
            <Link
              to={`${match.url}/${_id}/questionColleagueOfMale`}
              className="btn btn-light jr-btn-rounded">
              View Colleague Survey
            </Link>
            <Link
              to={`${match.url}/${_id}/questionFriendOfMale`}
              className="btn btn-light jr-btn-rounded">
              View Friend Survey
            </Link>
            <Link
              to={`${match.url}/${_id}/questionFamilyOfMale`}
              className="btn btn-light jr-btn-rounded">
              View Family Survey
            </Link>
          </ListGroupItem>
        </ListGroup>
      </div>
    </div>
  )
}

export default SurveyListItem
