import {
  FETCH_ALL_USERS,
  SHOW_MESSAGE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  PUT_USER,
  PUT_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  FETCH_ALL_ROLES,
  FETCH_ALL_ROLES_SUCCESS,
  FETCH_ALL_USERS_SUCCESS
} from './ActionTypes'

export const fetchUsers = () => ({
  type: FETCH_ALL_USERS
})

export const fetchRoles = () => ({
  type: FETCH_ALL_ROLES
})

export const fetchUsersSuccess = users => ({
  type: FETCH_ALL_USERS_SUCCESS,
  payload: users
})

export const fetchRolesSuccess = roles => ({
  type: FETCH_ALL_ROLES_SUCCESS,
  payload: roles
})

export const createUser = user => ({
  type: CREATE_USER,
  payload: user
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user
})

export const putUser = user => ({
  type: PUT_USER,
  payload: user
})

export const putUserSuccess = user => ({
  type: PUT_USER_SUCCESS,
  payload: user
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user
})
