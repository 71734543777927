import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import configureStore, { history } from './store'
import App from './containers/App'
import { PersistGate } from 'redux-persist/integration/react'
import CircularProgress from 'components/CircularProgress'
const { persistor, store } = configureStore()

const MainApp = () => (
  <Provider store={store}>
    <PersistGate
      loading={
        <div className="loader-view">
          <CircularProgress />
        </div>
      }
      persistor={persistor}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)

export const reduxStore = store
export default MainApp
