import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from 'components/Header/index'
import Sidebar from 'containers/SideNav/index'
import Footer from 'components/Footer'
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from 'actions/ActionTypes'
import { isIOS, isMobile } from 'react-device-detect'
import TopNav from 'components/TopNav'
import Programs from './routes/program'
import Evaluations from './routes/evaluation'
import Surveys from './routes/survey'
import Users from './routes/users'
import AppModule from './routes/appModule'
import URL from 'util/url';

import asyncComponent from '../util/asyncComponent'

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? 'collapsible-drawer'
        : 'mini-drawer'

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }
    const { printFriendly } = new URL().getParams();

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour /> */}

        <Sidebar />
        <div className="app-main-container">
          {
            !printFriendly ? <div className="app-header">
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === ABOVE_THE_HEADER && (
                      <TopNav styleName="app-top-header" />
                  )}
              <Header />
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
            </div>: null
          }

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/program`} component={Programs} />
                <Route
                  path={`${match.url}/evaluation`}
                  component={Evaluations}
                />
                <Route path={`${match.url}/survey`} component={Surveys} />
                <Route path={`${match.url}/user`} component={Users} />
                <Route
                  path={`${match.url}/resilienceReport`}
                  component={asyncComponent(() =>
                    import('./routes/resilienceReport')
                  )}
                />
                <Route path={`${match.url}/app-module`} component={AppModule} />
                <Route
                  component={asyncComponent(() =>
                    import('app/routes/extraPages/routes/404')
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        {/* <ColorOption/> */}
      </div>
    )
  }
}
const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings
  return { drawerType, navigationStyle, horizontalNavPosition }
}
export default withRouter(connect(mapStateToProps)(App))
