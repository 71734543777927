import {
  FETCH_ALL_PROGRAMS,
  FETCH_ALL_PROGRAMS_SUCCESS,
  FETCH_ALL_EVALUATIONS,
  FETCH_ALL_EVALUATIONS_SUCCESS,
  FETCH_ALL_SURVEYS,
  FETCH_ALL_SURVEYS_SUCCESS,
  FETCH_EVALUATION,
  FETCH_SURVEY,
  CREATE_PROGRAM,
  PUT_PROGRAM,
  DELETE_PROGRAM,
  DELETE_PROGRAM_SUCCESS,
  PUT_EVALUATION,
  DELETE_EVALUATION,
  FETCH_EVALUATION_SUCCESS,
  SEND_EVALUATION_REMINDER,
  FETCH_SURVEY_SUCCESS,
  FETCH_PROGRAM,
  FETCH_PROGRAM_SUCCESS,
  FETCH_USER_DATA_EVALUATIONS,
  DOWNLOAD_USER_PROGRAM_REPORT,
  DOWNLOAD_USER_PROGRAM_REPORT_SUCCESS,
  FETCH_USER_DATA_SUCCESS,
  ADD_USER_TO_PROGRAM,
  ADD_USER_TO_PROGRAM_SUCCESS,
  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  REMOVE_USER_TO_PROGRAM,
  REMOVE_USER_TO_PROGRAM_SUCCESS,
  SHOW_MESSAGE
} from './ActionTypes'

export const fetchProgram = _id => ({
  type: FETCH_PROGRAM,
  payload: _id
})

export const showMessage = message => ({
  type: SHOW_MESSAGE,
  payload: message
})

export const fetchPrograms = () => {
  return {
    type: FETCH_ALL_PROGRAMS
  }
}

export const fetchProgramsSuccess = programs => ({
  type: FETCH_ALL_PROGRAMS_SUCCESS,
  payload: programs
})

export const fetchProgramSuccess = program => ({
  type: FETCH_PROGRAM_SUCCESS,
  payload: program
})

export const fetchEvaluations = _ids => ({
  type: FETCH_ALL_EVALUATIONS,
  payload: { _ids }
})

export const fetchUserDataWithEvaluations = (programId, _id) => ({
  type: FETCH_USER_DATA_EVALUATIONS,
  payload: { programId, _id }
})

export const downloadUserProgramReport = (
  programId,
  userProgramId,
  isSample,
  emailUser
) => ({
  type: DOWNLOAD_USER_PROGRAM_REPORT,
  payload: { programId, userProgramId, isSample, emailUser }
})

export const fetchUserDataSuccess = userData => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: userData
})

export const fetchEvaluationsSuccess = evaluations => ({
  type: FETCH_ALL_EVALUATIONS_SUCCESS,
  payload: evaluations
})

export const downloadUserProgramReportSuccess = urlRes => ({
  type: DOWNLOAD_USER_PROGRAM_REPORT_SUCCESS,
  payload: urlRes
})

export const fetchEvaluation = _id => ({
  type: FETCH_EVALUATION,
  payload: { _id }
})

export const fetchSurvey = _id => ({
  type: FETCH_SURVEY,
  payload: { _id }
})

export const putEvaluation = (_id, evaluation) => ({
  type: PUT_EVALUATION,
  payload: { _id, evaluation }
})

export const deleteProgram = _id => ({
  type: DELETE_PROGRAM,
  payload: { _id }
})

export const putProgram = (_id, program) => ({
  type: PUT_PROGRAM,
  payload: { _id, program }
})

export const createProgram = program => ({
  type: CREATE_PROGRAM,
  payload: { program }
})

export const deleteProgramSuccess = _id => ({
  type: DELETE_PROGRAM_SUCCESS,
  payload: _id
})

export const fetchEvaluationSuccess = evaluation => ({
  type: FETCH_EVALUATION_SUCCESS,
  payload: evaluation
})

export const fetchSurveySuccess = survey => ({
  type: FETCH_SURVEY_SUCCESS,
  payload: survey
})

export const fetchSurveys = () => ({
  type: FETCH_ALL_SURVEYS
})

export const fetchSurveysSuccess = surveys => ({
  type: FETCH_ALL_SURVEYS_SUCCESS,
  payload: surveys
})

export const addUserToProgram = (userId, programId) => ({
  type: ADD_USER_TO_PROGRAM,
  payload: { userId, programId }
})

export const addUserToProgramSuccess = userProgram => ({
  type: ADD_USER_TO_PROGRAM_SUCCESS,
  payload: userProgram
})

export const removeUserToProgram = (userId, programId) => ({
  type: REMOVE_USER_TO_PROGRAM,
  payload: { userId, programId }
})

export const removeUserToProgramSuccess = userProgram => ({
  type: REMOVE_USER_TO_PROGRAM_SUCCESS,
  payload: userProgram
})

export const sendInvitation = (evaluation, userDataId) => ({
  type: SEND_INVITATION,
  payload: { evaluation, userDataId }
})

export const sendEvaluationReminder = _id => ({
  type: SEND_EVALUATION_REMINDER,
  payload: { _id }
})

export const deleteEvaluation = _id => ({
  type: DELETE_EVALUATION,
  payload: { _id }
})

export const sendInvitationSuccess = evaluation => ({
  type: SEND_INVITATION_SUCCESS,
  payload: evaluation
})
