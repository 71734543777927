import {
  FETCH_ALL_PROGRAMS,
  FETCH_ALL_PROGRAMS_SUCCESS,
  FETCH_ALL_EVALUATIONS,
  FETCH_ALL_EVALUATIONS_SUCCESS,
  FETCH_EVALUATION,
  PUT_EVALUATION,
  FETCH_EVALUATION_SUCCESS,
  FETCH_ALL_SURVEYS,
  FETCH_PROGRAM,
  CREATE_PROGRAM,
  PUT_PROGRAM,
  DELETE_PROGRAM,
  DELETE_PROGRAM_SUCCESS,
  FETCH_PROGRAM_SUCCESS,
  FETCH_SURVEY,
  FETCH_SURVEY_SUCCESS,
  FETCH_ALL_SURVEYS_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  FETCH_USER_DATA_EVALUATIONS,
  FETCH_USER_DATA_SUCCESS,
  ADD_USER_TO_PROGRAM,
  ADD_USER_TO_PROGRAM_SUCCESS,
  REMOVE_USER_TO_PROGRAM,
  REMOVE_USER_TO_PROGRAM_SUCCESS,
  DOWNLOAD_USER_PROGRAM_REPORT,
  DOWNLOAD_USER_PROGRAM_REPORT_SUCCESS,
  DELETE_EVALUATION,
  SEND_INVITATION_SUCCESS,
  SEND_EVALUATION_REMINDER,
  RESET
} from 'actions/ActionTypes'

const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: false,
  reportLoader: false,
  programs: [],
  surveys: [],
  program: {},
  evaluation: {},
  userData: {},
  /* evaluation list is small so won't need paging,
   * so we flatten and do not have `docs` fields */
  evaluations: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PROGRAMS:
    case FETCH_ALL_EVALUATIONS:
    case FETCH_EVALUATION:
    case PUT_EVALUATION:
    case FETCH_PROGRAM:
    case CREATE_PROGRAM:
    case PUT_PROGRAM:
    case DELETE_PROGRAM:
    case FETCH_SURVEY:
    case FETCH_USER_DATA_EVALUATIONS:
    case ADD_USER_TO_PROGRAM:
    case REMOVE_USER_TO_PROGRAM:
    case FETCH_ALL_SURVEYS: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: true
      }
    }
    case DOWNLOAD_USER_PROGRAM_REPORT: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        fileLoader: true
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case FETCH_ALL_PROGRAMS_SUCCESS: {
      return {
        ...state,
        loader: false,
        programs: action.payload.body
      }
    }
    case DELETE_PROGRAM_SUCCESS:
    case REMOVE_USER_TO_PROGRAM_SUCCESS:
    case SEND_INVITATION_SUCCESS:
    case ADD_USER_TO_PROGRAM_SUCCESS: {
      return {
        ...state,
        loader: false
      }
    }
    case FETCH_PROGRAM_SUCCESS: {
      return {
        ...state,
        loader: false,
        program: action.payload.body
      }
    }
    case FETCH_SURVEY_SUCCESS: {
      return {
        ...state,
        loader: false,
        survey: action.payload.body
      }
    }
    case DOWNLOAD_USER_PROGRAM_REPORT_SUCCESS: {
      return {
        ...state,
        fileLoader: false,
        userData: { ...state.userData, reportUrl: action.payload.body }
      }
    }
    case FETCH_USER_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload.body
      }
    }
    case FETCH_ALL_EVALUATIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        evaluations: action.payload.body
      }
    }
    case FETCH_ALL_SURVEYS_SUCCESS: {
      return {
        ...state,
        loader: false,
        surveys: action.payload.body
      }
    }
    case FETCH_EVALUATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        evaluation: action.payload.body
      }
    }
    case RESET: {
      return INIT_STATE
    }
    default:
      return state
  }
}
