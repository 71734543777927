import {
  AUTH_UPDATE_TOKEN,
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  RESET,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
} from 'actions/ActionTypes';
import { defineRulesFor } from '../permissions/ability'

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  rules: [],
  showMessage: false,
  initURL: '',
  authUser: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        rules: defineRulesFor(action.payload.body.user),
        authUser: action.payload.body
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        rules: defineRulesFor(action.payload.body.user),
        authUser: action.payload.body
      }
    }
    case AUTH_UPDATE_TOKEN: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          ...action.payload
        }
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        rules: [],
        authUser: null,
        initURL: '/app/program',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case RESET: {
      return {
        ...INIT_STATE,
        initURL: state.initURL // MUST keep initURL for autoLogin to work
      }
    }
    default:
      return state
  }
}
