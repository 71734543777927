/* eslint-disable indent */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'actions/ActionTypes'
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting'
import UserInfo from 'components/UserInfo'
import Menu from 'components/Header/Menu'
import _ from 'lodash'

class Header extends React.Component {
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  constructor() {
    super()
    this.state = {
      searchBox: false,
      searchText: '',
      mailNotification: false,
      langSwitcher: false,
      appNotification: false,
      userInfo: false
    }
  }

  render() {
    const {
      drawerType,
      locale,
      navigationStyle,
      horizontalNavPosition
    } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-flex d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? 'd-flex'
        : 'd-none'

    return (
      <div className="app-main-header">
        <div className="d-flex app-toolbar align-items-center">
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div className="app-logo-bl">
              <div className="d-block d-md-none">
                <span
                  className="jr-menu-icon"
                  onClick={this.onToggleCollapsedNav}>
                  <span className="menu-icon" />
                </span>
              </div>
              <div className="app-logo pointer d-none d-md-block">
                <img
                  className="d-none d-lg-block"
                  alt="..."
                  src="http://via.placeholder.com/105x36"
                />
                <img
                  className="d-block d-lg-none mr-3"
                  alt="..."
                  src="http://via.placeholder.com/32x32"
                />
              </div>
            </div>
          ) : (
            <span
              className={`jr-menu-icon pointer ${drawerStyle}`}
              onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon" />
            </span>
          )}

          {/* <SearchBox */}
          {/* styleName="d-none d-lg-block" */}
          {/* onChange={this.updateSearchText.bind(this)} */}
          {/* value={this.state.searchText} /> */}

          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item user-nav">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.userInfo}
                toggle={this.onUserInfoSelect.bind(this)}>
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <img
                    alt="..."
                    src={_.get(this.props.user, 'profileImageUrl')}
                    className="pointer user-avatar size-30"
                  />
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfo />
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition
  } = settings
  const user = _.get(auth, 'authUser.user', {})
  return {
    user,
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition
  }
}

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header)
)
