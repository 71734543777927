import * as _ from 'lodash';

export default class URL {
  params = {};

  constructor(url) {
    this.url = url;
    const params = new URLSearchParams(window.location.href?.split("?")?.[1]);
    this.params = Object.fromEntries(params);
  }

  setSlugs(params) {
    Object.keys(params).forEach((key) => {
      this.url = this.url.replace(`:${key}`, params[key]);
    });
    return this;
  }

  appendParams(newParams) {
    this.params = {
      ...this.params,
      ...newParams,
    };
    return this;
  }

  deleteParam(oldParam) {
    delete this.params[oldParam];
    return this;
  }

  replaceParams(params) {
    this.params = params;
    return this;
  }

  getParams() {
    return this.params;
  }

  getUrl() {
    const params = new URLSearchParams(
      _.omitBy(this.params, _.isNil)
    ).toString();
    return `${this.url}${params ? `?${params}` : ''}`;
  }
}
