import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Drawer from 'rc-drawer'
import SidenavLogo from 'components/SidenavLogo'
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from 'actions/ActionTypes'
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting'
import SidenavContent from './SidenavContent'

class SideNav extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    })
    // setTimeout(() => {
    //   this.props.updateWindowWidth(window.innerWidth)
    //   this.forceUpdate()
    // }, 1000)
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  render() {
    const {
      navCollapsed,
      drawerType,
      width,
      isDirectionRTL,
      navigationStyle
    } = this.props
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
        ? ''
        : 'd-flex'
    let type = true
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      type = false
    }
    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = ''
      type = false
    }

    return (
      <Drawer
        docked={type}
        className={`app-sidebar ${drawerStyle}`}
        style={{ overflow: 'auto' }}
        touch={true}
        position={isDirectionRTL ? 'right' : 'left'}
        transitions={false}
        enableDragHandle={false}
        open={navCollapsed}
        onOpenChange={this.onToggleCollapsedNav}
        sidebar={
          <div className="side-nav">
            <SidenavLogo drawerType={drawerType} />
            <SidenavContent />
          </div>
        }>
        <div />
      </Drawer>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    navCollapsed,
    drawerType,
    width,
    isDirectionRTL,
    navigationStyle
  } = settings
  return {
    navCollapsed,
    drawerType,
    width,
    isDirectionRTL,
    navigationStyle
  }
}

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
)
