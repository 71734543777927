import React from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import SurveyListItem from './Component/SurveyListItem'
import { connect } from 'react-redux'
import { fetchSurveys } from 'actions/index'
import _ from 'lodash'
import { Route, Switch } from 'react-router'
import asyncComponent from 'util/asyncComponent'
import SurveyViewer from 'app/routes/survey/Component/SurveyViewer'
import { ProgramNotification } from 'app/routes/program/components/ProgramNotification'

class SurveyList extends React.Component {
  componentDidMount() {
    this.props.fetchSurveys()
  }
  render() {
    const match = this.props.match
    return (
      <div>
        <Switch>
          <Route
            exact
            path={`${match.url}/:surveyId/:questionField`}
            render={props => <SurveyViewer {...props} onSubmit={() => {}} />}
          />
          <Route
            exact
            path={`${match.url}/`}
            render={() => (
              <SurveyListViewer match={match} surveys={this.props.surveys} />
            )}
          />
          <Route
            component={asyncComponent(() =>
              import('app/routes/extraPages/routes/404')
            )}
          />
        </Switch>
        <ProgramNotification />
      </div>
    )
  }
}

const SurveyListViewer = ({ surveys, match }) => {
  // console.log({surveys, match})
  return (
    <div className="app-wrapper">
      <ContainerHeader title={'Survey Templates'} match={match} />
      <div className="animated slideInUpTiny animation-duration-3">
        {surveys.map((p, index) => (
          <SurveyListItem
            key={index}
            data={p}
            match={match}
            styleName="card shadow "
          />
        ))}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchSurveys: () => dispatch(fetchSurveys())
})

const mapStateToProps = state => ({
  surveys: _.get(state, 'programs.surveys.docs', [])
})
export default connect(mapStateToProps, mapDispatchToProps)(SurveyList)
