import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchUsersSuccess,
  showMessage,
  createUserSuccess,
  putUserSuccess,
  fetchRolesSuccess,
  deleteUserSuccess
} from '../actions'
import {
  CREATE_USER,
  DELETE_USER,
  FETCH_ALL_USERS,
  FETCH_ALL_ROLES,
  PUT_USER
} from 'actions/ActionTypes'
import { securedClient } from '../sagas/api/APIClient'

const getUsers = async () =>
  securedClient()
    .then(client => client.apis.user.getUser())
    .then(d => d)
    .catch(error => error)

function* fetchUsersRequest() {
  try {
    const fetchedData = yield call(getUsers)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchUsersSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* fetchRolesRequest() {
  const getRoles = async () =>
    securedClient()
      .then(client => client.apis.role.getRole())
      .then(d => d)
      .catch(error => error)

  try {
    const fetchedData = yield call(getRoles)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield put(fetchRolesSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* createUserRequest({ payload }) {
  const postUser = async user =>
    securedClient()
      .then(client => client.apis.user.postUser({ body: [user] }))
      .then(d => d)
      .catch(error => error)

  try {
    const fetchedData = yield call(postUser, payload)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield call(fetchUsersRequest)
      yield put(createUserSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* deleteUserRequest({ payload }) {
  const deleteUser = async user =>
    securedClient()
      .then(client =>
        client.apis.user.deleteUser_id({
          _id: user._id
        })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const fetchedData = yield call(deleteUser, payload)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield call(fetchUsersRequest)
      yield put(deleteUserSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* putUserRequest({ payload }) {
  const putUser = async user =>
    securedClient()
      .then(client =>
        client.apis.user.putUser_id({ _id: user._id, body: user })
      )
      .then(d => d)
      .catch(error => error)

  try {
    const fetchedData = yield call(putUser, payload)

    // console.log(fetchedData)
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      yield call(fetchUsersRequest)
      yield put(putUserSuccess(fetchedData))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ALL_USERS, fetchUsersRequest),
    takeLatest(FETCH_ALL_ROLES, fetchRolesRequest),
    takeLatest(CREATE_USER, createUserRequest),
    takeLatest(PUT_USER, putUserRequest),
    takeLatest(DELETE_USER, deleteUserRequest)
  ])
}
