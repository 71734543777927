import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  SIGNIN_USER,
  SIGNIN_USER_WITH_TOKEN,
  SIGNOUT_USER,
  SIGNUP_USER
} from 'actions/ActionTypes'
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess
} from 'actions/Auth'
import { securedClient } from '../sagas/api/APIClient'
import { fetchEvaluationSuccess, showMessage } from 'actions/Program'

const createUserWithEmailPasswordRequest = async (email, password) =>
  securedClient()
    .then(client => client.apis.login.postLogin({ body: { email, password } }))
    .then(authUser => authUser)
    .catch(error => error)

const signInUserWithEmailPasswordRequest = async (email, password) =>
  securedClient()
    .then(client => client.apis.login.postLogin({ body: { email, password } }))
    .then(authUser => authUser)
    .catch(error => error)

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    )
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message))
    } else {
      yield put(userSignInSuccess(signInUser))
    }
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* signInUserWithToken({ payload }) {
  const fetchUser = async _id =>
    securedClient()
      .then(client => client.apis.user.getUser_id({ _id, $embed: ['role'] }))
      .then(authUser => authUser)
      .catch(error => error)

  const { token, userId } = payload
  try {
    // get User with token
    // set state
    let authUser = {
      body: {
        accessToken: token,
        refreshToken: token,
        user: {
          roleName: 'User'
        }
      }
    }
    // this temporary saves an authUser with only tokens
    yield put(userSignInSuccess(authUser))

    // fetch data
    const fetchedData = yield call(fetchUser, userId)
    console.log({ fetchedData })
    if (fetchedData.message) {
      yield put(showMessage(fetchedData.message))
    } else {
      authUser.body.user = fetchedData.body
      authUser.body.user.roleName = _.get(fetchedData, 'body.role.name', 'User')
      yield put(userSignInSuccess(authUser))
    }
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    )
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message))
    } else {
      // localStorage.setItem('user_id', signUpUser.uid)
      yield put(userSignUpSuccess(signUpUser))
    }
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* signOut() {
  try {
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message))
    } else {
      // localStorage.removeItem('user_id')
      yield put(userSignOutSuccess(signInUser))
    }
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

export function* createUserAccount() {
  yield takeLatest(SIGNUP_USER, createUserWithEmailPassword)
}

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword)
  yield takeLatest(SIGNIN_USER_WITH_TOKEN, signInUserWithToken)
}

export function* signOutUser() {
  yield takeLatest(SIGNOUT_USER, signOut)
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(createUserAccount), fork(signOutUser)])
}
